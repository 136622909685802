"use client";
import ProductCard from "./ProductCard";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";

const Slider = ({ data }) => {
  return (
    <div className="relative mt-5">
      <Carousel
        opts={{
          align: "start",
          loop: true,
          slidesToScroll: "auto",
        }}
        className="w-full"
      >
        <CarouselContent>
          {data?.map((book, i) => (
            <CarouselItem
              key={i}
              className="basis-1/2 md:basis-1/4 lg:basis-1/6"
            >
              <ProductCard product={book} />
            </CarouselItem>
          ))}
        </CarouselContent>

        <CarouselPrevious className="absolute top-1/2 transform -translate-y-1/2 left-2" />
        <CarouselNext className="absolute top-1/2 transform -translate-y-1/2 right-2" />
      </Carousel>
    </div>
  );
};

export default Slider;
