"use client";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const SchoolImage = ({ school }) => {
  return (
    <LazyLoadImage
      src={school?.image}
      alt={school?.name}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = "/placeholder.jpg";
      }}
      effect="blur"
      className="w-full object-contain h-full"
    />
  );
};

export default SchoolImage;
