"use client";
import ProductCard from "./ProductCard";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";

const Slider = ({ data }) => {
  return (
    <div className="relative  mt-5 w-full bg-gray-100">
      <Carousel
        opts={{
          align: "start",
          loop: true,
          slidesToScroll: "auto",
        }}
        className="w-full"
      >
        <CarouselContent>
          {data?.slice(0, 20)?.map((item, i) => (
            <CarouselItem
              key={i}
              className="basis-1/2 md:basis-1/4 lg:basis-1/6  w-full "
            >
              <ProductCard brand={item} />
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious className="absolute top-1/2 transform -translate-y-1/2 left-2" />
        <CarouselNext className="absolute top-1/2 transform -translate-y-1/2 right-2" />
      </Carousel>
    </div>
  );
};

export default Slider;
