"use client";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import Image from "next/image";
const Hero = ({ data }) => {
  return (
    <Carousel
      opts={{
        align: "center",
        loop: true,
      }}
      plugins={[
        Autoplay({
          delay: 5000,
        }),
      ]}
      className="w-full "
    >
      <CarouselContent>
        {data?.map((data, index) => (
          <CarouselItem key={index}>
            <Image
              src={data?.image}
              alt="hero section"
              width={1200}
              height={100}
              className="object-cover m-0 p-0 w-full h-full"
              quality={100}
              loading="lazy"
            />
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  );
};

export default Hero;
