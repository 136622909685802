import Link from "next/link";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ProductCard = ({ brand }) => {
  return (
    <div className="h-full py-4 px-2 rounded-md hover:scale-100 transition-all duration-500">
      <Link
        href={`/brand?search=${brand?.name}`}
        title="Explore our brands"
        className="h-full bg-white flex justify-center items-center  py-4 px-2 rounded-md hover:scale-105 transition-all duration-700"
      >
        <LazyLoadImage
          src={brand?.image || "/dummyauthor.jpg"}
          alt={brand?.name}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/placeholder.jpg";
          }}
          effect="blur"
          className="w-24  h-24 object-contain rounded-full inline-block p-2"
        />
      </Link>
    </div>
  );
};

export default ProductCard;
