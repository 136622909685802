import { trimText } from "@/lib/trimText";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ProductCard = ({ author }) => {
  return (
    <div className="bg-white border flex flex-col justify-between py-4 px-2 rounded-md hover:scale-105 transition-all duration-500">
      <div className="space-y-4 text-center">
        <LazyLoadImage
          src={author?.image || "/dummyauthor.jpg"}
          alt={author?.name}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/placeholder.jpg";
          }}
          effect="blur"
          className="w-32 h-32 object-cover rounded-full inline-block bg-gray-100 p-2"
        />

        <p className="text-sm capitalize font-medium text-custom-text-blue">
          {trimText(author?.name, 36)}
        </p>
      </div>
      <div className="flex justify-center">
        <Link href={`/author-books?search=${author?.name}`} className="mt-2">
          <Button variant="outline">View all books</Button>
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
