"use client";
import Link from "next/link";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import SchoolImage from "@/components/layout/SchoolImage";
import { trimText } from "@/lib/trimText";

const AcademicCards = ({ data }) => {
  return (
    <div className="mt-5 grid gap-4 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {data?.map((item, i) => (
        <Card
          key={i}
          className="flex flex-col items-center p-4"
        >
          <CardContent className="flex flex-col items-center">
            <Link
              href={`/academics/collections?school=${item?.name}&class=${item?.schoolClass[0]}`}
              aria-label={`View details for ${item?.name}`}
              className="h-40 w-full overflow-hidden flex items-center justify-center"
            >
              <SchoolImage
                school={item}
                className="object-cover h-full w-full"
              />
            </Link>
          </CardContent>
          <CardFooter className="w-full">
            <Link
              href={`/academics/collections?school=${item?.name}&class=${item?.schoolClass[0]}`}
              className="bg-white text-center flex items-center justify-center w-full mt-2 p-2"
            >
              <p className="text-base font-medium capitalize">
                {trimText(item?.name, 20)}
              </p>
            </Link>
          </CardFooter>
        </Card>
      ))}
    </div>
  );
};

export default AcademicCards;
